import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Rob Garland"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Rob Garland"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"rob"}
                fluid={data.robGarlandImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text
              as="h1"
              fontWeight="bold"
              mb="1.2rem"
              mt={["2rem", null, "0rem", null]}
            >
              Rob Garland
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and producer
            </Text>
            <Box>
              <Link to="https://www.instagram.com/robgarlandla" as="a">
                <Instagram mr="1.2rem" />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Rob Garland grew up in England, worked in a diverse array of bands
              and later played hundreds of gigs at festivals and clubs in the
              U.S. with his band 'The Blue Monks,' opening for artists such as
              B.B. King and Chuck Berry. He now lives in Los Angeles, where he
              continues to write and record his original music and regularly
              performs at clubs such as at The Baked Potato and The Mint. He has
              recorded sessions at studios such as The Village Recorder,
              Revolver and J.J. Abrams’ Bad Robot.
            </Text>
            <Text level="2" mt="3.6rem">
              Rob has been teaching guitar for over 25 years and has taught
              hundreds of private students across all ability levels and musical
              genres. He served on the faculty at Musicians Institute (G.I.T.)
              in Hollywood, CA and has created numerous instructional courses
              for TrueFire & JamPlay, including the best selling 'Chord
              Navigator: CAGED' series. He has also written instructional books
              for Cherry Lane and for Fundamental Changes, including 'The CAGED
              Rhythm Guitar Method' in 2023.{" "}
            </Text>
            <Text level="2" mt="3.6rem">
              {" "}
              Musicians Rob has performed with include Steve Vai, Jimmy Haslip,
              Marco Minnemann and Tony Newton. He is endorsed by Bogner amps,
              James Tyler guitars, Curt Mangan strings, etc.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Rob Garland" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    robGarlandImage: file(relativePath: { eq: "robGarland-L.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
